<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2" max-width="1000" style="margin: auto">
            <v-card-title>
              <span class="mb_max_width">Collaborations</span>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="user_is_not_customer"
                    v-bind="attrs"
                    v-on="on"
                    class="mt-0 pt-0 mr-4"
                    @click="add_collaborator_dialog = true"
                    large
                    color="primary"
                    >mdi-plus-circle</v-icon
                  >
                </template>
                <span>Add Collaborator</span>
              </v-tooltip>
              <div class="table_search_field mb_max_width">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :search="search"
                :headers="
                  !selected_table && !user_is_not_customer
                    ? headers_collaborations
                    : headers_collaborators
                "
                :items="
                  !selected_table && !user_is_not_customer
                    ? collaborations
                    : collaborators
                "
                :loading="loading"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                no-data-text="No collaborations"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab v-if="!user_is_not_customer">Sharing With</v-tab>
                    <v-tab
                      ><v-badge
                        bordered
                        color="oc_orange"
                        :value="pending_badge"
                        dot
                      >
                        Shared to Me
                      </v-badge>
                    </v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    text-color="white"
                    small
                    class="mr-1"
                    v-if="item.approved"
                    color="oc_green"
                    ><b>Approved</b></v-chip
                  >
                  <v-chip
                    text-color="white"
                    small
                    class="mr-1"
                    v-else
                    color="oc_dark_grey"
                    ><b>Pending</b></v-chip
                  >
                </template>
                <template v-slot:[`item.permission`]="{ item }">
                  <div
                    v-if="
                      item.approved ||
                      (!selected_table && !user_is_not_customer)
                    "
                  >
                    <v-chip
                      text-color="white"
                      small
                      class="mr-1"
                      :color="access_color_obj[item.permission]"
                      ><b>{{ access_naming_obj[item.permission] }}</b></v-chip
                    >
                  </div>
                  <div v-else>-</div>
                </template>

                <template v-slot:[`item.delete`]="{ item }">
                  <v-icon class="mr-2" @click="select_collaborator(item)"
                    >mdi-account-edit-outline</v-icon
                  >
                  <v-icon
                    @click="
                      delete_item = item;
                      delete_dialog = true;
                    "
                    >mdi-trash-can-outline</v-icon
                  >
                </template>
                <template v-slot:[`item.approved`]="{ item }">
                  <v-chip
                    v-if="item.approved"
                    text-color="white"
                    small
                    class="mr-1"
                    :color="'oc_green'"
                    ><b>Approved</b></v-chip
                  >
                  <div v-else>
                    <v-btn
                      @click="
                        approval_item = item;
                        approval = true;
                        approval_dialog = true;
                      "
                      text-color="white"
                      small
                      class="mr-3"
                      :color="'primary'"
                      ><b>Approve</b></v-btn
                    >
                    <v-btn
                      @click="
                        approval_item = item;
                        approval = false;
                        approval_dialog = true;
                      "
                      text-color="white"
                      small
                      :color="'primary'"
                      ><b>Decline</b></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.delete_1`]="{ item }">
                  <v-icon
                    v-if="item.owner_email != 'tutorial@onecup.ai' && item.approved"
                    @click="
                    cancel_colaboration = true;
                      delete_item = item;
                      delete_dialog = true;
                    "
                    >mdi-trash-can-outline</v-icon
                  >
                </template>
                <template v-slot:[`item.notifications`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div>
                        <v-icon
                          color="oc_green"
                          v-bind="attrs"
                          v-on="on"
                          v-if="
                            item.owner_email != 'tutorial@onecup.ai' &&
                            item.approved &&
                            item.notifications_enabled
                          "
                          @click="
                            enable_disable_notification(item.owner_email, false)
                          "
                          >mdi-bell-ring-outline</v-icon
                        >
                        <v-icon
                          color="oc_dark_grey"
                          v-bind="attrs"
                          v-on="on"
                          v-if="
                            item.owner_email != 'tutorial@onecup.ai' &&
                            item.approved &&
                            !item.notifications_enabled
                          "
                          @click="enable_disable_notification(item.owner_email, true)"
                          >mdi-bell-cancel-outline</v-icon
                        >
                      </div> </template
                    ><span v-if="item.notifications_enabled"
                      >Disable notifications</span
                    ><span v-else>Enable notifications</span></v-tooltip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row></v-container
    >

    <v-dialog
      v-model="add_collaborator_dialog"
      max-width="900px"
      min-width="860px"
    >
      <v-card>
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">
                {{
                  edit_collaborator ? "Edit Collaborator" : "Add Collaborator"
                }}
              </h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="add_collaborator_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-row>
            <v-col cols="12">
              <v-form v-model="form_valid">
                <v-text-field
                  outlined
                  dense
                  :clearable="!edit_collaborator"
                  :readonly="edit_collaborator"
                  :rules="[
                    validateEmail,
                    !edit_collaborator ? validate_collab_with_exists : true,
                  ]"
                  v-model="new_collaborator.collaborator_email"
                  label="Collaborator Email*"
              /></v-form>
            </v-col>

            <v-col cols="4" class="pt-0 mt-0"
              ><h4>Permission:</h4>
              <div style="width: 80%" class="pt-4">
                <v-select
                  outlined
                  dense
                  v-model="new_collaborator.permission"
                  :items="access_naming_obj_select"
                  label=""
                ></v-select>
              </div>
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions class="pb-2 pt-2 mr-2">
          <v-row>
            <v-col class="text-right">
              <div>
                <v-btn
                  color="gray"
                  width="120"
                  @click="add_collaborator_dialog = false"
                  class="mr-4"
                >
                  Cancel
                </v-btn>
                <v-btn
                  style="float: right"
                  color="primary"
                  width="120"
                  :disabled="!form_valid"
                  @click="
                  add_collaborator()
                  "
                  v-if="this"
                >
                  Save
                </v-btn>
              </div></v-col
            >
          </v-row>
          <span class="error_span an_err">{{ error }}</span>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog v-model="delete_dialog" max-width="500px" min-width="400px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">
                {{
                  !selected_table
                    ? "Delete Collaborator"
                    : "Cancel Collaboration"
                }}
              </h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>
        <v-card-actions class="pb-2 pt-2 mr-2">
          <v-row>
            <v-col class="text-right">
              <div>
                <v-btn
                  color="gray"
                  width="120"
                  @click="delete_dialog = false"
                  class="mr-4"
                >
                  Cancel
                </v-btn>
                <v-btn
                  style="float: right"
                  color="primary"
                  width="120"
                  @click="delete_collaborator"
                >
                  Delete
                </v-btn>
              </div></v-col
            >
          </v-row>
          <span class="error_span an_err">{{ error }}</span>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog v-model="approval_dialog" max-width="500px" min-width="400px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">
                {{
                  approval ? "Approve Collaborator" : "Decline Collaboration"
                }}
              </h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="approval_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>
        <v-card-actions class="pb-2 pt-2 mr-2">
          <v-row>
            <v-col class="text-right">
              <div>
                <v-btn
                  color="gray"
                  width="120"
                  @click="approval_dialog = false"
                  class="mr-4"
                >
                  Cancel
                </v-btn>
                <v-btn
                  style="float: right"
                  color="primary"
                  width="120"
                  @click="confirm_collaboration"
                >
                  {{ approval ? "Approve" : "Decline" }}
                </v-btn>
              </div></v-col
            >
          </v-row>
          <span class="error_span an_err">{{ error }}</span>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="error.length ? 'error' : 'success'"
    >
      {{ error.length ? error : success }}
    </v-snackbar>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import { validateEmail } from "@/utilities/ValidationRules";
// import { eventBus } from "../main.js";
import { initializeLambdaClient } from "@/utilities/SessionUtils";
import { InvokeCommand } from "@aws-sdk/client-lambda";

export default {
  components: {
    navbar,
    sidenav,
    //events,
  },
  props: ["filter"],
  data() {
    return {
      search: "",
      staging_cameras: [],
      production_cameras: [],
      staging_production: true,
      loading: false,
      lambda: null,
      editable: false,
      edit_value: null,
      new_collaborator: {permission: 'READ'},
      edit_collaborator: false,
      add_collaborator_dialog: false,
      selected_table: 0,
      form_valid: false,
      delete_item: false,
      delete_dialog: false,
      snackbar: false,
      success: "",
      error: "",
      cancel_colaboration: false,
      approval_dialog: false,
      approval: false,
      approval_item: false,
      access_naming_obj_select: [
        { text: "Read", value: "READ" },
        { text: "Modify", value: "WRITE" },
      ],
      access_naming_obj: {
        NONE: "No Access",
        READ: "Read",
        WRITE: "Modify",
      },
      access_color_obj: {
        NONE: "oc_dark_grey",
        READ: "oc_green",
        WRITE: "oc_green",
      },
    };
  },
  methods: {
    validate_collab_with_exists(email) {
      for (const i in this.collaborations) {
        if (email == this.collaborations[i].collaborator_email) {
          return "A collaborator with this email already exists.";
        }
      }
      return true;
    },
    select_collaborator(item) {
      this.new_collaborator = { ...item };
      this.edit_collaborator = true;
      this.add_collaborator_dialog = true;
    },

    async enable_disable_notification(email, ed) {
      var params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
                InvocationType: "Event",

        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: "UPDATE_NOTIFICATIONS_PREFERENCE",
          collaborator_email: this.$store.getters.getUser.attributes.email,
          main_user_email: email,
          notifications_enabled: ed,
          suppress_notifications: false,
        }),
      };

      var _this = this;

      var command = new InvokeCommand(params);

      this.lambda
        .send(command)
        .then(() => {
           _this.loading = true;
          setTimeout(() => {
            _this.snackbar = true;
            _this.success = ed
              ? "Enabled Notifications"
              : "Disabled Notifications";
            _this.$store.dispatch("DDB_GET_COLLABORATIONS");
            _this.$store.dispatch("DDB_GET_COLLABORATORS");
            _this.edit_collaborator = false;
            _this.loading = false;
          }, 2500);
        })
        .catch((err) => {
          _this.snackbar = true;
          _this.error = err.stack;
        });
    },
    async confirm_collaboration() {
      var params = {};

      params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
        InvocationType: "Event",
        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: "APPROVAL",
          approved: this.approval,
          collaborator_email: this.$store.getters.getUser.attributes.email,
          main_user_email: this.approval_item.owner_email,
          permission: "READ",
          suppress_notifications: false,
        }),
      };

      var _this = this;

      var command = new InvokeCommand(params);

      this.lambda
        .send(command)
        .then(() => {
          _this.loading = true;
          setTimeout(() => {
            _this.loading = false;
            _this.snackbar = true;
            _this.success = "Approved collaboration";
            _this.$store.dispatch("DDB_GET_COLLABORATIONS");
            _this.$store.dispatch("DDB_GET_COLLABORATORS");
          }, 2500);
          _this.approval_dialog = false;
        })
        .catch((err) => {
          _this.snackbar = true;
          _this.error = err.stack;
        });
    },
    async delete_collaborator() {
      var operation = this.cancel_colaboration
        ? "DELETE_COLLABORATOR"
        : "DELETE_MAIN_USER";
      var params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
        InvocationType: "Event",
        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: operation,
          collaborator_email: this.cancel_colaboration
            ? this.$store.getters.getUser.attributes.email
            : this.delete_item.collaborator_email,
          main_user_email: this.cancel_colaboration
            ? this.delete_item.owner_email
            : this.$store.getters.getUser.attributes.email,
            permission: this.delete_item.permission,
          suppress_notifications: false,
        }),
      };
      var _this = this;

      var command = new InvokeCommand(params);

      this.lambda
        .send(command)
        .then(() => {
          _this.loading = true;
          setTimeout(() => {
            _this.snackbar = true;
            _this.success = "Deleted collaborator";
            _this.$store.dispatch("DDB_GET_COLLABORATIONS");
            _this.$store.dispatch("DDB_GET_COLLABORATORS");

            if (_this.cancel_colaboration) {
              _this.$store.dispatch(
                "DDB_GET_CUSTOMER_ID",
                _this.$store.getters.getUser.username
              );
            }
            _this.loading = false;
          }, 2500);
          _this.delete_dialog = false;
        })
        .catch((err) => {
          _this.snackbar = true;
          _this.error = err.stack;
          // _this.edit_collaborator = false;
        });
    },
    add_collaborator() {
      if (!this.form_valid) {
        this.snackbar = true;
        this.error = "Wrong Request";
        return false;
      }


      const operation = this.edit_collaborator ? "UPDATE" : "CREATE";
      var params = {
        FunctionName: process.env.VUE_APP_MULTIUSER_LAMBDA /* required */,
        InvocationType: "Event",
        Payload: JSON.stringify({
          auth_token: this.$store.getters.getJwtToken,
          operation_type: operation,
          collaborator_email: this.new_collaborator.collaborator_email,
          main_user_email: this.$store.getters.getUser.attributes.email,
          permission: this.new_collaborator.permission,
          suppress_notifications: false,
          notifications_enabled: true
        }),
      };

      var _this = this;

      var command = new InvokeCommand(params);

      this.lambda
        .send(command)
        .then(() => {
          _this.add_collaborator_dialog = false;
          const ec = _this.edit_collaborator;
          _this.loading = true;
          setTimeout(() => {
            _this.snackbar = true;
            _this.success = ec
              ? "Updated Collaborator"
              : "Created Collaborator";
              this.$store.dispatch("DDB_GET_COLLABORATIONS");
      this.$store.dispatch("DDB_GET_COLLABORATORS");
            _this.edit_collaborator = false;
            _this.loading = false;
          }, 2500);
        })
        .catch((err) => {
          _this.snackbar = true;
          _this.error = err.stack;
          // _this.edit_collaborator = false;
        });
    },

    validateEmail,
  },
  created() {
    this.$store.dispatch("DDB_GET_COLLABORATIONS");
    this.$store.dispatch("DDB_GET_COLLABORATORS");
    initializeLambdaClient().then((client) => {
      this.lambda = client;
    });
  },
  computed: {
    user_is_not_customer() {
      return (
        this.$store.getters.getUserDDB &&
        this.$store.getters.getUserDDB.type == "USER"
      );
    },
    headers_collaborations() {
      return [
        {
          text: "Collaborator",
          value: "collaborator_email",
          width: "220px",
        },
        { text: "Approval", value: "status" },
        { text: "Permission", value: "permission", sortable: false },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "88px",
        },
      ];
    },

    headers_collaborators() {
      return [
        {
          text: "Main User",
          value: "owner_email",
          width: "220px",
        },
        { text: "Approval", value: "approved", sortable: false },
        { text: "Permission", value: "permission", sortable: false },


        {
          text: "",
          value: "delete_1",
          sortable: false,
          width: "44px",
        },
      ];
    },

    collaborations() {
      return [...this.$store.getters.getCollaborations];
    },

    collaborators() {
      return [...this.$store.getters.getCollaborators];
    },

    pending_badge() {
      if (this.$store.getters.getUserDDB) {
        for (const i in this.$store.getters.getUserDDB
          .customer_is_collaborator) {
          if (!this.$store.getters.getUserDDB.is_collaborator[i].approved) {
            return true;
          }
        }
      }

      return false;
    },
  },
  watch: {
    add_collaborator_dialog() {
      if (!this.add_collaborator_dialog) {
        this.new_collaborator = {permission: 'READ'};
        this.edit_collaborator = false;
        this.success = "";
        this.error = "";
      }
    },
    delete_dialog() {
      if (!this.delete_dialog) {
        this.delete_item = false;
        this.cancel_colaboration = false;
        this.success = "";
        this.error = "";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
